import React from "react";

import Layout from "../../components/Layout";

import * as Constants from "../../lib/constants";

import * as styles from "./DataUsage.module.css";

const DataUsageContainer = ({ path }) => {
  return (
    <Layout
      metaDesc="Data Usage Information for Tobo apps."
      title="What Data We Collect"
      ogUrlPath="/data-usage/"
      canonicalPath="/data-usage/"
    >
      <main className={styles.container}>
        <h2>What Data We Collect</h2>
        <ul>
          <li>
            Data and analytics about your use of the mobile app (e.g. your progress in the app, the
            date and time you used the app, session length, features you have used, your in-app
            purchase history, your interaction with advertisements)
          </li>
          <li>
            Your IP address and mobile device identifiers (such as your device ID, advertising ID,
            MAC address, IMEI)
          </li>
          <li>
            General location data (e.g. country or city-level location) inferred from your IP
            address
          </li>
          <li>
            Non-identifying data about your device, such as device name and operating system,
            browser type, language and other technical data like screen size or processor, or
            combination of this data available for a developer for checking the device compatibility
            with a particular Service
          </li>
          <li>
            Your in-app purchase data from Apple App Store and Google Play Store. Note that we do
            not collect or store your financial data, such as your credit card number or bank
            account. You are requested to provide payment details directly to the relevant platform.
            We may, however, receive limited non-financial data relating to the purchase (e.g.
            platforms may notify us if a purchase was successful). Any post-purchase processes are
            controlled by the platforms.
          </li>
          <li>
            E-mail address and password if you decide to sign up for a Tobo account. Passwords are
            not stored in readable form.
          </li>
        </ul>
        <h2>Why We Collect Data</h2>
        <ul>
          <li>
            <b>To make the app work:</b> The data we collect enables us to facilitate, operate,
            provide and maintain the Tobo app, recognize you when you return to the app, verify and
            confirm payments in the app, detect software errors to improve the app
          </li>
          <li>
            <b>To serve you advertising:</b> Tobo app partners with advertisers to display
            personalized or non-personalized ads within the app and you agree that our tools may
            provide any of the data we have collected from you to advertising partners. You can
            check "Opt-out of personalized advertising" section if you'd like to disable
            personalized ads.
          </li>
        </ul>
        <h2>How We Share Your Data</h2>
        <p>
          The app does use third-party services that may collect information used to identify you.
        </p>{" "}
        <ul>
          <li>
            <b>3rd Party Advertising Partners:</b> Tobo app integrates third-party advertising
            networks to display ads within the app and you agree that our tools may provide any of
            the data we have collected from you to advertising partners.
          </li>
          <li>
            <b>Reports/Analytics Providers:</b> Our analytics providers may provide us with
            information regarding your use of our Services (such as in-app events, your interaction
            with the Services and in-app advertisements, software errors, etc.) so that we could
            improve our Services.
          </li>
        </ul>
        <div>
          <p>Link to the privacy policy of third-party service providers used by the app</p>{" "}
          <ul>
            <li>
              <a
                href="https://www.google.com/policies/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Play Services
              </a>
            </li>
            <li>
              <a
                href="https://support.google.com/admob/answer/6128543?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                AdMob
              </a>
            </li>
            <li>
              <a
                href="https://firebase.google.com/policies/analytics"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Analytics for Firebase
              </a>
            </li>
          </ul>
        </div>{" "}
        <h3>Non-personalized ads (NPA)</h3>
        <p>
          Non-personalized ads are ads that are not based on a user’s past behavior. They are
          targeted using contextual information, including coarse (such as city-level) geo-targeting
          based on current location, and content on the current site or app or current query terms.
          Although non-personalized ads don’t use cookies or mobile ad identifiers for ad targeting,
          they do still use cookies or mobile ad identifiers for frequency capping, aggregated ad
          reporting, and to combat fraud and abuse.
        </p>
        <h3>Personalized ads</h3>
        <p>
          To serve you personalized advertisements in the Service using third-party advertising
          networks we will rely on your consent to process necessary data to:
        </p>
        <ul>
          <li>
            Track the content you access in connection with the Services and your online behavior
          </li>
          <li>Deliver, target and improve advertising in our Service</li>
        </ul>
        <p>You can opt out of personalized advertising by following the instructions below.</p>
        <h3>Opt-out of personalized advertising</h3>
        <p>
          You can opt-out of personalized advertising on mobile applications by checking the privacy
          settings of your Android or iOS device.
        </p>
        <strong>iOS devices</strong>
        <p>For iOS 14:</p>
        <ol>
          <li>Open Settings</li>
          <li>Select Privacy</li>
          <li>Select Tracking and disable “Allow Apps to Request to Track”</li>
        </ol>
        <p>For iOS 13 and older:</p>
        <ol>
          <li>Open Settings</li>
          <li>Select Privacy</li>
          <li>Select Advertising and enable "Limit Ad Tracking"</li>
        </ol>
        <strong>Android devices</strong>
        <ol>
          <li>Open Settings</li>
          <li>Select Google</li>
          <li>Select Ads and enable "Opt out of Ads Personalization"</li>
        </ol>
        <strong>Manage your choices:</strong>
        <ul>
          <li>
            <a href="http://optout.networkadvertising.org/">
              Network Advertising Initiative Consumer Opt-Out Page
            </a>
          </li>
          <li>
            <a href="https://optout.aboutads.info/">
              the Digital Advertising Alliance Opt-Out Page
            </a>
          </li>
          <li>
            <a href="https://www.youronlinechoices.com/uk/your-ad-choices">
              Your Online Choices website
            </a>
          </li>
        </ul>
        <p>
          All of the opt-out tools described in this subsection are provided by third parties, not
          by us. We do not control or operate these tools or the choices that advertisers and others
          provide through these tools.
        </p>
        <p>
          Please note that opting out does not mean that you will stop seeing advertisements in our
          Services, rather third-party advertising networks will show you contextual advertisements
          not tailored to your interests. Please, be aware that such actions may result in decrease
          of advertising quality and less enjoyable user experience.
        </p>
      </main>
    </Layout>
  );
};

export default DataUsageContainer;
